export default {
    methods: {
        cacheStorage(type, name, data = null, expireDate = null) {
            if (type === 'set') {
                localStorage.setItem(name, JSON.stringify(data));
                if (expireDate) {
                    localStorage.setItem(name + "time", expireDate);
                }
            }
            if (type === 'get') {
                if (localStorage.getItem(name) || localStorage.getItem(name + "time")) {
                    let expireDate = localStorage.getItem(name + "time");
                    if (expireDate) {
                        if (expireDate < Date.now()) {
                            localStorage.removeItem(name);
                            return false;
                        }
                    }

                    try {
                        return JSON.parse(localStorage.getItem(name));
                    } catch (e) {
                        localStorage.removeItem(name);
                        return null;
                    }
                }

                return null;
            }
        },
        getCache() {
            this.phone = this.$root.cacheStorage('get', 'phone');

            const cachedShop = this.$root.cacheStorage('get', 'shop');
            const shop = this.shops.find((shopItem) => shopItem.id === cachedShop?.id);

            if (shop) {
                this.shop = shop;
            } else if (this.orderType === 'takeaway') {
                this.shop = this.shops[0];
            }

            let deliveryAddress = this.$root.cacheStorage('get', 'deliveryAddress');
            if (deliveryAddress) {
                this.addr = deliveryAddress.address;
            }

            let orderTypeFromCache = this.cacheStorage('get', 'orderType');
            if (orderTypeFromCache !== false) {
                this.orderType = orderTypeFromCache;
                if (!this.orderType) {
                    if (this.shop?.id) {
                        this.orderType = 'takeaway'
                    }
                    if (this.addr.formated) {
                        this.orderType = 'delivery'
                    }

                    if (!this.orderType) {
                        this.orderType = this.city.delivery ? 'delivery' : 'takeaway';
                    }

                    if (this.orderType) {
                        let expireDate = new Date().getTime() + this.$root.cacheExpireDate;
                        this.cacheStorage('set', 'orderType', this.orderType, expireDate);
                    }
                }
            }

            let changeOrderType = new URL(location.href).searchParams.get('changeOrderType')
            if (changeOrderType) {
                this.orderType = changeOrderType
            }

            let changeShop = new URL(location.href).searchParams.get('changeShop')
            if (changeShop) {
                this.orderType = 'takeaway'
            }

            // if (!this.orderType
            //     || orderTypeFromCache === false
            //     || (this.orderType == 'takeaway' && !this.shop.id)
            //     || (this.orderType == 'delivery' && !this.addr.formated)
            // ) {
            //     this.$root.openModal('selectOrderType', {orderType: this.orderType});
            // }
        }
    },
    created() {
        //Если есть сохраненный адрес, то показываем его
        this.getCache();
    }
}

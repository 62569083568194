<template>
    <div id="deliveryMap" class="deliveryMap" ref="map"></div>
</template>

<script>
import { getCustomDeliveryTime } from "../../axios/axios_time";
import { addressByCoordinates } from "../../axios/axios_geocoder";

export default {
    data() {
        return {
            map: null
        }
    },
    methods: {
        getCustomDeliveryTime,
        addressByCoordinates,
        fitToContainer() {
            if (this.map) {
                this.$nextTick(() => {
                    this.map.container.fitToViewport();
                });
            }
        },
        initMap() {
            ymaps.ready(() => {
                let coords = Object.values(this.$root.city.coord);
                let isCityCoords = true

                if (this.$root.addr.coordinates && this.$root.addr.coordinates.latitude && this.$root.addr.coordinates.longitude) {
                    isCityCoords = false
                    coords = [ this.$root.addr.coordinates.latitude, this.$root.addr.coordinates.longitude ]
                }

                this.map = new ymaps.Map(this.$refs.map, {
                    center: coords,
                    zoom: !this.$root.city.mapZoom || this.$root.city.mapZoom < 12 ? 12 : this.$root.city.mapZoom,
                    autoFitToViewport: 'always',
                    controls: [],
                }, {suppressMapOpenBlock: true});

                this.map.events.add('click', () => {
                    this.$parent.$refs['geocoder'].status = ''
                    this.$parent.outsideArea = true

                    this.$root.clearDataOfAddress()

                    this.$root.cookingTimeDelivery = null
                    this.$root.addr.zoneId = null
                });

                let zoomControl = new ymaps.control.ZoomControl({
                    options: {
                        size: 'small',
                        position: {
                            right: 18,
                            top: 108
                        }
                    }
                });

                this.map.events.add('click', (e) => this.getAddress(e.get('coords')));
                this.map.geoObjects.events.add('click', (e) => this.onClickZone(e));

                let geolocationControl = new ymaps.control.GeolocationControl({
                    options: {
                        position: {
                            right: 18,
                            top: 190
                        }
                    }
                });
                this.map.controls.add(zoomControl);
                this.map.controls.add(geolocationControl);

                if (!isCityCoords) {
                    this.getAddress(coords);
                }

                this.getDeliveryZones(ymaps, this.map, true);
            });
        },
        onClickZone(e) {
            this.$parent.$refs['geocoder'].status = ''
            this.getAddress(e.get('coords'));
            let target = e.get('target');

            if (target) {
                const coords = e.get('coords');

                let data = null
                this.$parent.outsideArea = false;

                data = this.getPolygonDataByCoords(coords)

                if (data) {
                    this.$parent.outsideArea = false;
                    this.$root.isActualize = true;

                    this.getCustomDeliveryTime(data.zoneId)
                        .then(({data}) => this.$root.cookingTimeDelivery = data.minutes)
                        .finally(() => this.$root.isActualize = false);

                    this.$root.addr.found = true
                    this.$root.addr.zoneId = data.zoneId
                    this.$root.addr.coordinates = {
                        latitude: coords[0],
                        longitude: coords[1]
                    };
                } else {
                    this.$parent.outsideArea = true
                }
            }
        },
        getPolygonDataByCoords(coords) {
            let data = null
            this.map.geoObjects.each(item => {
                if (item.geometry.getType() !== 'Polygon')
                    return

                if (item.geometry.contains(coords)) {
                    data = item.properties._data
                    return false
                }
            })
            return data
        },
        getAddress(coords) {
            this.addressByCoordinates({latitude: coords[0], longitude: coords[1]})
                .then(({data}) => this.$parent.$refs['geocoder'].value = data.formated);

            const placemark = new ymaps.Placemark(coords, {}, {
                iconLayout: 'default#image',
                iconImageHref: '/images/icons/yap_metka.svg',
                iconOffset: [-15, -15],
                iconImageSize: [50, 50]
            });

            this.map.geoObjects.each((geoObject) => {
                if (geoObject.geometry.getType() === 'Point') this.map.geoObjects.remove(geoObject);
            });

            this.map.geoObjects.add(placemark);
        },
        navigateTo(coords, zoom = 15) {
            if (!this.map || !coords?.latitude || !coords?.longitude) return;

            if (this.map && coords.latitude) this.map.setCenter([ coords.latitude, coords.longitude ], zoom);

            this.getAddress([ coords.latitude, coords.longitude ]);
        },
    },
    mounted() {
        if (!this.map) {
            this.initMap();

            ymaps.ready(() => {
                this.map.setZoom(!this.$root.city.mapZoom || this.$root.city.mapZoom < 12 ? 12 : this.$root.city.mapZoom);
            });

            ymaps.ready(() => {
                this.$root.deliveryMapInitialized = true;
            });
        }
    }
}
</script>

<style scoped>

</style>

import {declOfNum} from "../../mixins/app/declOfNum";

export function addTakeawayShops(yMap, context){
    const shops = context.$root.actualShops;

    const MyBalloonLayout =  ymaps.templateLayoutFactory.createClass(`
                <div class="headerYandexPickupMap__shop headerYandexPickupMap__shop--for-map">
                    $[[options.contentLayout]]
                    <button class="headerYandexPickupMap__shop--select-shop {% if properties.isActive %} headerYandexPickupMap__shop--selected-shop {% endif %}" data-id="{{ properties.shopId }}">
                        {% if properties.isActive %}
                            Выбрано
                        {% else %}
                            Выбрать
                        {% endif %}
                    </button>
                    <div class="headerYandexPickupMap__arrow"></div>
                </div>`,
        {
            /**
             * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
             * @function
             * @name build
             */
            build: function () {
                const activeShopId = context.$root.shop.id;
                const shopId = this.getData().properties.get('shopId');

                if (Number(activeShopId) !== Number(shopId)) {
                    this.getData().properties.set('isActive', false);
                } else {
                    this.getData().properties.set('isActive', true);
                }

                this.constructor.superclass.build.call(this);

                this._$element = this.getParentElement().getElementsByClassName('headerYandexPickupMap__shop--for-map')[0];

                this.applyElementOffset();

                this._onCloseClick = this.onCloseClick.bind(this);

                this._$element.getElementsByClassName('headerYandexPickupMap__close')[0].addEventListener('click', this._onCloseClick, true);
                this._$element.getElementsByClassName('headerYandexPickupMap__shop--select-shop')[0].addEventListener('click', this.onSelectShopClick, true);
            },

            /**
             * Удаляет содержимое макета из DOM.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
             * @function
             * @name clear
             */
            clear: function () {
                this._$element.getElementsByClassName('headerYandexPickupMap__close')[0].removeEventListener('click', this.onCloseClick, true)
                this._$element.getElementsByClassName('headerYandexPickupMap__shop--select-shop')[0].removeEventListener('click', this.onSelectShopClick, true)
                this.constructor.superclass.clear.call(this);
            },

            /**
             * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name onSublayoutSizeChange
             */
            onSublayoutSizeChange: function () {
                MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                if (!this._isElement(this._$element)) {
                    return;
                }

                this.applyElementOffset();

                this.events.fire('shapechange');
            },

            /**
             * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name applyElementOffset
             */
            applyElementOffset: function () {
                let left = -(this._$element.offsetWidth / 2);
                let top = -(this._$element.offsetHeight + 75);
                this._$element.setAttribute('style', `left: ${left}px; top: ${top}px`);
            },

            /**
             * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name onCloseClick
             */
            onCloseClick: function (e) {
                e.preventDefault();
                this.events.fire('userclose');
            },

            /**
             * Устанавливает активную точку.
             * @function
             * @name onSelectShopClick
             */
            onSelectShopClick: function (e) {
                e.preventDefault();

                const id = e.target.dataset.id;
                context.selectShop(id, true);
            },

            /**
             * Используется для автопозиционирования (balloonAutoPan).
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
             * @function
             * @name getClientBounds
             * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
             */
            getShape: function () {
                if (!this._isElement(this._$element)) {
                    return MyBalloonLayout.superclass.getShape.call(this);
                }

                let position = this._$element.position();

                return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                    [position.left, position.top], [
                        position.left + this._$element[0].offsetWidth,
                        position.top + this._$element[0].offsetHeight + 68
                    ]
                ]));
            },

            /**
             * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
             * @function
             * @private
             * @name _isElement
             * @param {jQuery} [element] Элемент.
             * @returns {Boolean} Флаг наличия.
             */
            _isElement: function (element) {
                return element && element[0] && element.find('.address-schedule__arrow')[0];
            }
    });
    const MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(`$[properties.balloonContent]`);

    if (shops) {
        let pointsCollection = {
            type: "FeatureCollection",
            features: []
        }

        shops.forEach((shop) => {
            const workingTime = shop.workingHours
                .filter(item => item.date === null)
                .filter(item => item.type === 'online_orders')
                .find(item => {
                    return item.day === window.moment().isoWeekday()
                });

            let params = {};

            if (workingTime) {
                params = {
                    openColor: isTimeBetween(shop.workingTime[0].from, shop.workingTime[0].to) ? '#00BF53' : '#f00',
                    openText: isTimeBetween(shop.workingTime[0].from, shop.workingTime[0].to) ?
                        `Открыто до ${shop.workingTime[0].to}`
                        : `Закрыто до ${shop.workingTime[0].from}`,
                    openTakingOrder: `Ежедневно ${shop.workingTime[0].from} - ${shop.workingTime[0].to}`,
                    openOrder: `Приём заказов с ${shop.onlineOrder[0].from} до ${shop.onlineOrder[0].to}`
                };
            } else {
                params = {
                    openColor: '#f00',
                    openText: 'Закрыто на выходной',
                    openTakingOrder: '',
                    openOrder: ''
                };
            }

            let balloonContent = `
                    <button class="headerYandexPickupMap__close">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
                            </svg>
                    </button>
                    <div class="headerYandexPickupMap__shop--title">
                        ${shop.address}
                    </div>
                    <div class="headerYandexPickupMap__shop--cooking-time">
                        <span class="headerYandexPickupMap__shop--cooking-time-title">Текущее время ожидания</span>
                        <div class="headerYandexPickupMap__shop--cooking-time-minute">${shop.customCookingTime.minutes} ${declOfNum(shop.customCookingTime.minutes,['минута', 'минуты', 'минут'])}</div>
                    </div>
                    <div class="headerYandexPickupMap__shop--work-time">
                        <p @click="showTime">
                            <svg style="margin-top: 5px" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="4" cy="4" r="4" fill="${params.openColor}"/>
                            </svg>
                            ${params.openText}
                        </p>
                        <ul class="headerYandexPickupMap__shop--work-time--for-map headerYandexPickupMap__shop--all-work-time">
                            <li>${params.openTakingOrder}</li>
                            <li>${params.openOrder}</li>
                        </ul>
                    </div>`;

            const mapIconLayout = ymaps.templateLayoutFactory.createClass(
                `<div class="headerYandexPickupMap__icon"></div>`
            );

            const point = {
                type: 'Feature',
                id: shop.id,
                geometry: {
                    type: 'Point',
                    coordinates: [shop.coord.latitude, shop.coord.longitude],
                },
                properties: {
                    shopId: shop.id,
                    shop: shop,
                    balloonContent: balloonContent,
                    isActive: false
                },
                options: {
                    iconLayout: mapIconLayout,
                    iconShape: {
                        type: 'Rectangle',
                        coordinates: [
                            [-21, -48], [21, 0]
                        ]
                    },
                    balloonShadow: false,
                    balloonLayout: MyBalloonLayout,
                    balloonContentLayout: MyBalloonContentLayout,
                    balloonPanelMaxMapArea: 0,
                    hideIconOnBalloonOpen: false,
                }
            };

            pointsCollection.features.push(point)
        });

        const objectManager = new ymaps.ObjectManager({
            clusterIconShape: {
                type: 'Rectangle',
                coordinates: [
                    [-21, -48], [21, 0]
                ]
            },

            /**
             * Ставим true, если хотим кластеризовать только точки с одинаковыми координатами.
             */
            groupByCoordinates: false,

            /**
             * Опции кластеров указываем в кластеризаторе с префиксом "cluster".
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ClusterPlacemark.xml
             */
            clusterDisableClickZoom: false,
            clusterHideIconOnBalloonOpen: true,
            clusterOpenBalloonOnClick: false,
            geoObjectHideIconOnBalloonOpen: false
        });

        objectManager.add(pointsCollection);
        yMap.geoObjects.add(objectManager);

        // Центрируем метку, при открытии ballon, по её координатам на карте
        objectManager.objects.events.add('balloonopen', function (event) {
            const objectId = event.get('objectId');
            const object = objectManager.objects.getById(objectId);
            const coordAddForOpen = {
                21: 0.00008, 20: 0.00016, 19: 0.00031, 18: 0.00064, 17: 0.0013, 16: 0.0025, 15: 0.005,
                14: 0.01, 13: 0.02, 12: 0.04, 11: 0.08, 10: 0.14, 9: 0.19, 8: 0.55,
                7: 0.85, 6: 1.7, 5: 2.2, 4: 2.5, 3: 3, 2: 7, 1: 8, 0: 9
            };

            yMap.setCenter([parseFloat(object.geometry.coordinates[0]) + coordAddForOpen[Math.round(yMap.getZoom())], object.geometry.coordinates[1]],
                yMap.getZoom(), {
                    checkZoomRange: true,
                    duration: 500
            });
        });

        setBounds(context, yMap);
    }
}

function setBounds(context, yMap) {
    if (!context.$root.shop.id) {
        let bounds = yMap.geoObjects.getBounds();

        if(bounds){
            yMap.container.fitToViewport();
            yMap.setBounds(yMap.geoObjects.getBounds(), {
                checkZoomRange: true,
                useMapMargin: true,
                zoomMargin: 10
            }).then((response) => {
                yMap.setZoom(11);
            });
        }
    }
}

export function isTimeBetween(startTime, endTime) {
    const currentTime = window.moment();
    const start = window.moment(startTime, 'HH:mm:ss');
    const end = window.moment(endTime, 'HH:mm:ss');

    if (end.isBefore(start)) {
        end.add(1, 'day');
    }

    return currentTime.isBetween(start, end);
}

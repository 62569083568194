<template>
    <div class="headerYandexPickupMap" :class="{'active': isShowShopList }">
        <button class="headerYandexPickupMap__check-list-shops" @click="$emit('closeListShop')">
            Посмотреть список суши-баров
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2542 10L20 16.504L13.2542 23" stroke="#ED1F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <div style="padding: 0 16px">
            <div class="block-group__item block-group__item--address">
                <input type="text" class="block-group__input"
                       v-model="address"
                       required>
                <label class="block-group__label">Введите адрес суши-бара</label>
                <div class="block-group__icon" style="top: 12px; right: 12px;">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#ED1F24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="headerYandexPickupMap__list-shops">
            <shop-map v-for="shop in filteredShops"
                      v-bind:data="shop"
                      v-bind:key="shop.id"
                      :shop="shop"
                      @onClickShop="onClickShop" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isShowShopList: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            address: null,
        }
    },
    watch: {
        isShowShopList(val) {
            if (!val) {
                this.address = null;
            }
        }
    },
    computed: {
        filteredShops() {
            return this.$root.actualShops.filter((shop) => !this.address || shop.address.toLowerCase().includes(this.address.toLowerCase()));
        }
    },
    methods: {
        onClickShop(shop) {
            this.$emit('selectShop', shop.id);
        }
    }
}
</script>

<style scoped>
    .active {
        display: block;
    }
</style>

<template>
    <div v-show="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <yandex-address-picker
            ref="geocoder"
            :bbox="$root.city.bbox"
            :city-name="$root.city.name"
            :map-value="$root.addr.formated"
            @close="close"
            @showListShop="isShowShopList = true"
        />

        <list-shop-map
            :is-show-shop-list="isShowShopList"
            @selectShop="$refs['takeaway_map'].selectShop($event, $root.window.width <= 950)"
            @closeListShop="isShowShopList = false"/>

        <delivery-map v-if="showDeliveryMap"
                      ref="delivery_map"
                      v-show="isDelivery" />

        <takeawayMap v-if="showDeliveryMap"
                     ref="takeaway_map"
                     v-show="!isDelivery" />
    </div>
</template>

<script>
import radioSlider from "../radio-slider.vue";
import ListShopMap from "../cart/listShopMap.vue";
import DeliveryMap from "../map/deliveryMap.vue";
import TakeawayMap from "../map/takeawayMap.vue";
import { getCustomDeliveryTime } from "../../axios/axios_time";

export default {
    name: 'select-delivery-address',
    components: {DeliveryMap, TakeawayMap, ListShopMap, radioSlider},

    data() {
        return {
            showDeliveryMap: false,
            map: null,
            isShowShopList: false,
            addr: null,
            outsideArea: false,
        }
    },

    watch: {
        isShow(val) {
            if (val) {
                if (this.$root.addr.formated) {
                    this.$refs['geocoder'].value = this.$root.addr.formated;
                }

                ymaps.ready(() => {
                    if (this.isDelivery && this.$refs['delivery_map'].map) {
                        this.$refs['delivery_map'].fitToContainer();
                    }

                    if (!this.isDelivery && this.$refs['takeaway_map'].map) {
                        this.$refs['takeaway_map'].fitToContainer();
                    }
                });

                this.showMap();
            }
        },
        isDelivery(val) {
            if (val) {
                this.isShowShopList = false;
            }
        },
        '$root.orderType'(value) {
            let expireDate = new Date().getTime() + this.$root.cacheExpireDate;
            this.$root.cacheStorage('set', 'orderType', value, expireDate);
        },
    },

    methods: {
        getCustomDeliveryTime,
        open() {
            this.$root.openModal(this.$options.name);
        },
        close() {
            this.$root.closeModal(this.$options.name);
            this.hideMap();
        },

        async showMap() {
            this.showDeliveryMap = true;
        },

        hideMap() {
            // this.showDeliveryMap = false;
        },

        saveDelivery() {
            const save = {
                city_id: this.$root.city.id,
                address: this.$root.addr,
                requirements: this.requirements
            };

            this.$root.cacheStorage('set', 'orderType', 'delivery', new Date().getTime() + this.$root.cacheExpireDate);
            this.$root.cacheStorage('set', 'deliveryAddress', save);

            this.$eventBus.$emit('addressSelected');

            this.close();
        },

        searchDelivery(response) {
            this.$root.unavailableClientAddress.save = false;
            this.$root.addr.delivered = true;
            this.requirements = response.requirements;

            if (this.$root.addr.status === 'saving') {
                this.$root.addr = response;
                this.$root.addr.status = 'saving';
            } else {
                this.$root.addr = response;
            }

            if (this.$root.addr.status !== 'saving') {
                if (!response.delivered) {
                    this.$root.addr.status = 'not_delivered';
                } else {
                    this.$root.addr.status = 'delivered';
                }
            }

            if (response.entrance !== null) {
                this.$root.addr.entrance = response.entrance;
            }

            if (this.$root.addr.status === 'saving' && response.delivered) {
                //уже сохраняется, нужно попытаться завершить если адрес входит в доставку
                this.$root.addr.status = 'delivered';
                this.saveDelivery();
            }
        },
    },

    computed: {
        isShow() {
            return this.$root.modal.open.name === this.$options.name;
        },
        isDelivery() {
            return this.$root.orderType === 'delivery';
        },
    },
    mounted() {
        if (this.$root.addr.formated && this.$root.addr.zoneId) {
            this.$root.isActualize = true;

            this.getCustomDeliveryTime(this.$root.addr.zoneId)
                .then(({data}) => this.$root.cookingTimeDelivery = data.minutes)
                .finally(() => this.$root.isActualize = false);
        }
    }
}
</script>

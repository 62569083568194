<template>
    <div class="block-group__items block-group__items--code">
        <div class="block-group__item block-group__item--type-code">
            <div class="block-group__code"
                 @click="setCode('uds')"
                 :class="{'block-group__code--active': isUds}">Код UDS</div>
            <div class="block-group__code"
                 @click="setCode('promo')"
                 :class="{'block-group__code--active': isPromo}">Промокод</div>
        </div>
        <div class="block-group__item block-group__item--certificate"
             v-show="isUds">
            <input type="text" class="block-group__input vue-masked"
                   inputmode="numeric"
                   v-model="$root.uds.key"
                   data-maska="### ###"
                   pattern="[0-9]{3} [0-9]{3}"
                   @keydown.enter="applyUds"
                   required
                   :readonly="uds.applied">
            <label class="block-group__label"
                   :style="{'color': uds.applied ? '#00BF53' : 'rgb(138, 138, 138)'}">{{ labelUds }}</label>
            <div class="block-group__icon"
                 v-show="uds.applied"
                 @click="clearUds"
                 style="top: 17px; right: 19px; cursor: pointer">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
                </svg>
            </div>
        </div>
        <div class="block-group__item block-group__item--certificate"
             v-show="isPromo">
            <input type="text" class="block-group__input"
                   inputmode="numeric"
                   @keydown.enter="applyPromocode($root.promocode.key)"
                   v-model="$root.promocode.key"
                   required
                   :readonly="appliedPromocode">
            <label class="block-group__label">Введите промокод</label>
            <div class="block-group__icon"
                 v-show="appliedPromocode"
                 @click="clearPromocode"
                 style="top: 17px; right: 19px; cursor: pointer">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#8A8A8A"/>
                </svg>
            </div>
        </div>
        <div class="block-group__item block-group__item--use_uds" v-show="isUds && !uds.applied">
            <label>
                <input type="checkbox" class="block-group__item--checkbox"
                       v-model="uds.needCertificate"
                       required>
                <span>Использовать сертификат UDS</span>
            </label>
        </div>
        <div class="block-group__item block-group__item--info_uds">
            <template v-if="appliedPromocode && $root.typeCode === 'promo'">
                <p v-if="!$root.promocode.error">Ваш промокод успешно активирован!</p>
                <p v-if="appliedPromocode.item.type === 'present'">
                    {{ !$root.promocode.error ? 'К Вашему заказу добавлен:' : 'К Вашему заказу НЕ добавлен:' }} {{ appliedPromocode.item.product.name }} {{ parseInt(appliedPromocode.item.sum) }}
                    {{ appliedPromocode.item.product.quantityInProduct }} шт / {{ appliedPromocode.item.product.weight.value.original }} {{ appliedPromocode.item.product.weight.unit }}
                </p>
                <p v-if="appliedPromocode.item.type === 'absolutDiscount'">
                    Скидка на Ваш заказ {{ appliedPromocode.item.sum }}
                </p>
                <p v-if="appliedPromocode.item.type === 'relativeDiscount'">
                    Скидка на Ваш заказ {{ appliedPromocode.item.persent }}%
                </p>
            </template>
            <template v-if="$root.typeCode === 'uds' && uds.applied">
                <p>Доступно к списанию {{ uds.customer.points }} баллов{{ uds.receipt.points < uds.customer.point ?
                    ` , оплатить можно до ${uds.needCertificate ? uds.purchase.pointsPercent : uds.customer.membershipTier.maxScoresDiscount} от суммы чека.` : '.' }}</p>
                <p>С сертификата будет списано: {{ $root.uds.applied ? $root.uds.receipt.points : 0 }} баллов.</p>
                <p>Будет начислено {{ uds.purchase.cashBack }} баллов.</p>
            </template>
        </div>
    </div>
</template>

<script>
import { MaskInput } from "maska";

export default {
    methods: {
        setCode(code) {
            if (this.$root.promocode.key) {
                this.$root.isActualize = true;
            }

            this.$root.typeCode = code;
        },
        applyPromocode() {
            this.$parent.applyPromocode(this.$root.promocode.key);
        },
        applyUds() {
            this.$parent.applyUds(this.$root.uds.key);
        },
        clearPromocode() {
            this.$parent.clearPromocode();
        },
        clearUds() {
            this.$parent.clearUds();
        }
    },
    watch: {
        '$root.basket.cart.promocode'(promocode) {
            if (promocode) {
                this.$root.promocode.key = promocode.key;
            }
        },
        '$root.promocode.key'(value) {
            if (value.length >= 6) {
                this.applyPromocode(this.$root.promocode.key);
            }
        },
        '$root.typeCode'(value) {
            if (value === 'promo') {
                this.clearUds();
            } else {
                this.clearPromocode();
            }
        },
        'uds.needCertificate'() {
            this.clearUds();
        }
    },
    computed: {
        isPromo() {
            return this.$root.typeCode === 'promo';
        },
        hasUds() {
            return window.initialState.hasUds;
        },
        isUds() {
            return this.$root.typeCode === 'uds';
        },
        appliedPromocode() {
            return this.$root.basket.cart.promocode;
        },
        uds() {
            return this.$root.uds;
        },
        labelUds() {
            const needCertificate = this.uds.needCertificate;

            if (this.uds.applied) {
                return needCertificate ? 'Код сертификата введён верно' : 'Код  UDS введён верно';
            }

            if (this.uds.key) {
                return needCertificate ? 'Код сертификата' : 'Код UDS';
            }

            return needCertificate ? 'Введите код сертификата' : 'Введите код UDS';
        }
    },
    mounted() {
        new MaskInput('.vue-masked');

        if (this.$root.basket.cart.promocode) {
            this.$root.promocode.key = this.$root.basket.cart.promocode.key;
            this.$root.typeCode = 'promo';
        }
    }
}

</script>

<style scoped>

</style>
